<template>
  <CommonSliderDrawerFullPage
    :openDrawer="showModal"
    @closeModal="() => (showModal = false)"
    :showFullPage="true"
  >
    <div class="flex md:h-full flex-col h-full md:pb-0 bg-white">
      <div>
        <div
          class="flex md:flex-row flex-col md:max-w-7xl md:mx-auto md:relative px-4 md:mt-8 mt-2 bg-white"
        >
          <div class="cursor-pointer flex leading-7 pb-2" @click="closeModal">
            <NuxtImg
              loading="lazy"
              class="mx-2 w-2"
              src="/icons/left-arrow.svg"
              alt="back left arrow icon"
              width="8"
              height="13"
            />
            Back
          </div>
          <ClinicSelectedBlock :item="clinicTest"></ClinicSelectedBlock>
        </div>
      </div>

      <Transition name="slide-fade">
        <div
          class="h-100vh bg-secondary-lighter pt-10 mt-6 md:mt-0 overflow-y-auto"
        >
          <CommonPanelContent @openCheckPanel="handleCheckoutEvent" :hideChecked="false" :startPoint="startPoint" />
        </div>
      </Transition>
    </div>
  </CommonSliderDrawerFullPage>
</template>
<script setup>
import { useCheckoutStore } from "~/stores/checkout";
import { useProductStore } from "~/stores/filter";
import { useMapStore } from "~~/stores/map";
const checkoutStore = useCheckoutStore();
const route = useRoute();
const store = useProductStore();
const mapStore = useMapStore();
const props = defineProps({
  showModal: Boolean,
  isCityPage: {
    type:Boolean,
    default:false
  },
  startPoint: String
});

const clinicTest = computed(() => mapStore.getSelectedClinic);

const emit = defineEmits(["close","onBackClick",'openCheckPanel']);
function handleCheckoutEvent() {
  emit('openCheckPanel')
}
const closeModal = () => {
	if((!route.path.includes('tests'))){
		  checkoutStore.removeTestLists()
	}
  mapStore.reset()
  emit('onBackClick')
  store.clearAllFilters();
 // mapStore.closePanel()
  return navigateTo(({
			path: route.path,
	}));

};
const startPoint = ref("clinic-page");
if (props.isCityPage) {
  startPoint.value = "city-page";
}
</script>
